<template>
<div class="bg--login">
  <header-block />
  <div class="container container--tests">
    <div class="tests">
      <div class="test test--1">
        <img
          src="@/assets/images/test.png"
          srcset="@/assets/images/test@2x.png 2x" alt="img" class="test__img"
        >
        <h3 class="test__tit">
          Тест «Любовь к себе»
        </h3>
        <div class="button__block">
          <router-link to="/self/start" class="button">
            Пройти тест
          </router-link>
        </div>
        <div class="button__block">
          <router-link to="/self/secret" class="button--br">
            Читать секрет
          </router-link>
        </div>
      </div>
      <div class="test test--2">
        <img
          src="@/assets/images/test_2.png"
          srcset="@/assets/images/test_2@2x.png 2x" alt="img" class="test__img"
        >
        <h3 class="test__tit">
          Тест «Насколько гармоничны ваши отношения с партнером?»
        </h3>
        <div class="button__block">
          <router-link to="/couple/start" class="button">
            Пройти тест
          </router-link>
        </div>
        <div class="button__block">
          <router-link to="/couple/secret" class="button--br">
            Читать секрет
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'Home',
};
</script>
